import {
    get,
    post,
    put,
    Delete
}
from './http'

    //获取分页
export const apiTopicPage = p => get('/back/page', p)
    //获取分页
export const userInfoPage = p => get('/userInfo/page', p)
    // 删除
export const carDelete = p => post('/back/delete', p)
    //新增
export const carSave = p => post('/back/save', p)
  // 修改
export const carUpdate = p => put('/back/update', p)

export const userUpdate = p => put('/back/userUpdate', p)
export const getOrderPage = p => get('/back/getOrderPage', p)
export const getOrReturnCar = p => put('/back/getOrReturnCar', p)
export const apiLogin = p => post('/back/login', p)
export const orderInfoPage = p => get('/back/orderInfoPage', p)
export const activityCarPage = p => get('/back/activityCarPage', p)
export const activityCarSave = p => post('/back/activityCarSave', p)
export const activityCarUpdate = p => put('/back/activityCarUpdate', p)
export const activityCarDelete = p => get('/back/activityCarDelete', p)
export const activityInfoPage = p => get('/activityInfo/page', p)
export const activityInfoSave = p => post('/activityInfo', p)
export const activityinfoUpdate = p => put('/activityInfo', p)
export const activityinfoDelete = p => Delete('/activityInfo/', p)
export const activityinfoList = p => get('/activityInfo/list', p)
export const activitySendMessage = p => post('/AliYunSms/appointmentNotice', p)
export const makeAnAppointmentList = p => get('/back/makeAnAppointmentList', p)
export const carSeriesSave = p => post('/carSeries', p)
export const carSeriesPage = p => get('/carSeries/page', p)
export const carSeriesUpdate = p => put('/carSeries', p)
export const carSeriesDelete = p => Delete('/carSeries/', p)
export const costTablePage = p => get('/costTable/page', p)
export const costTableUpdate = p => put('/costTable', p)
export const carSeries = p => get('/homePage/carSeries', p)
export const userUseCar = p => get('/back/userUseCar', p)
export const userRechargeCount = p => get('/back/userRechargeCount', p)
export const carUse = p => get('/back/carUse', p)
export const homePage = p => get('/back/homePage', p)